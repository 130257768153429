/* eslint-disable import/prefer-default-export */
// Don't require a default export. Gatsby's API can't support it here.
import PropTypes from 'prop-types';
import './src/styles/index.css';
import React from 'react';
import '@contentful/live-preview/style.css';
import { ContentfulLivePreviewProvider } from '@contentful/live-preview/react';

export const wrapRootElement = ({ element }) => (
  <ContentfulLivePreviewProvider locale="en-US">{element}</ContentfulLivePreviewProvider>
);

wrapRootElement.propTypes = {
  element: PropTypes.element.isRequired,
};
